const initialState = {
  data: null,
  loading: false,
  singleData: null,
};

const CourseSetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_COURSE_SET":
      return {
        data: null,
        loading: true,
      };

    case "COURSE_SET_FETCH_SUCCESS":
      return {
        loading: false,
        data: payload,
      };

    case "COURSE_SET_FETCH_SUCCESS_SINGLE":
      return {
        ...state,
        loading: false,
        singleData: payload,
      };

    case "COURSE_SET_FETCH_FAILURE":
      return {
        data: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default CourseSetReducer;
