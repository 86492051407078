import { AuthAPI } from "../../../Components/Auth/AuthAPI";
import { CONSTANTS } from "../../../constants";

export const RegisterUserRequest = () => {
  return {
    type: "REGISTER_USER_REQUEST",
  };
};

export const RegisterUserSuccess = (payload) => {
  return {
    type: "REGISTER_USER_SUCCESS",
    payload: payload,
  };
};

export const RegisterUserError = (payload) => {
  return {
    type: "REGISTER_USER_ERROR",
    payload: payload,
  };
};

export const ClearMessage = () => {
  return {
    type: "CLEAR_MESSAGE",
  };
};

export const RegisterUser = (credentials, navigate) => {
  console.log("Credentails", credentials);
  return async (dispatch) => {
    dispatch(RegisterUserRequest());
    await AuthAPI.getRegsitrationCode(credentials)
      .then((res) => {
        if (res.data.status) {
          dispatch(
            RegisterUserSuccess({
              message:
                "Verification Code Has Been Successfully Sent To Your Email.",
              data: res.data,
              credentials: credentials,
            })
          );
          navigate("/verify-code");
        } else {
          dispatch(RegisterUserError("Failed to Register"));
        }
      })
      .catch((e) => {
        if (e.response?.data) {
          dispatch(RegisterUserError(e.response.data.message));
        } else {
          dispatch(RegisterUserError(e.message));
        }
      });

    dispatch(ClearMessage());
  };
};
