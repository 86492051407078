const initialState = {
  data: null,
  loading: false,
  info: null,
  reload: false,
  message: {
    success: null,
    error: null,
  },
};

const BankingDetailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "BUY_COURSE_REQUEST":
      return {
        ...state,
        data: null,
        loading: true,
      };

    case "BUY_COURSE_SUCCESS":
      return {
        ...state,
        loading: false,
        data: payload,
        message: {
          success: "Your request has been sent successfully. You'll be notified soon.",
          error: null,
        },
      };

    case "BUY_COURSE_FAILURE":
      return {
        ...state,
        data: null,
        loading: false,
        message: {
          success: null,
          error: payload,
        },
      };

    case "BUY_PACKAGE_REQUEST":
      return {
        ...state,
        info: null,
        reload: true,
      };

    case "BUY_PACKAGE_SUCCESS":
      return {
        ...state,
        reload: false,
        info: payload,
        message: {
          success: "Your request has been sent successfully. You'll be notified soon.",
          error: null,
        },
      };

    case "BUY_PACKAGE_FAILURE":
      return {
        ...state,
        info: null,
        reload: false,
        message: {
          success: null,
          error: payload,
        },
      };

    case "CLEAR_MESSAGE":
      return {
        ...state,
        message: {
          success: null,
          error: null,
        },
      };

    default:
      return state;
  }
};

export default BankingDetailReducer;
