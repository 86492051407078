const initialState = {
    data:null,
    loading:false,
    message:{
        message:null,
        error:null
    }
}

export const ChangeForgotPasswordReducer = (state = initialState, {type, payload}) => {
    switch(type){
        case "FORGOT_PASSWORD_REQUEST": 
            return{
            ...state,
            loading:true
        };
        case "FORGOT_PASSWORD_SUCCESS":
            return {
                loading: false,
                data: payload.data,
                message: {
                  success: payload.message,
                  error: null,
                },
            };
        case "FORGOT_PASSWORD_ERROR":
            return {
                data: null,
                loading: false,
                message: {
                  error: payload,
                  success: null,
                },
              };
              case "CLEAR_MESSAGE":
      return {
        ...state,
        loading: false,
        message: {
          error: null,
          success: null,
        },
      };
    default:
      return state;
    }
};