import React, { useState } from "react";

import { NavLink } from "react-router-dom";

import TopHeader from "./TopHeader";

const Header = (props) => {
  return (
    <div>
      <TopHeader />

      <nav className=" navbar navbar-expand-lg mb-2 bg-light navbar-light  ">
        <div className="menu-toggle-bar">
          <button
            className="navbar-toggler "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>

        <div className="sec-header-main">
          <div className="collapse navbar-collapse  " id="navbarNav">
            <ul className="navbar-nav">
              <li className="">
                <NavLink to="/" className="link">
                  <p>
                    <i class="fa-solid fa-house-chimney"></i>
                  </p>
                </NavLink>
              </li>
              <li className="">
                <a href="https://loksewabazaar.com/designation/1">
                  <p>प्रथम पत्र</p>
                </a>
              </li>
              <li className="">
                <a href="https://loksewabazaar.com/designation/2">
                  <p>नायब सुब्बा</p>
                </a>
              </li>
              <li className="">
                <a href="https://loksewabazaar.com/videos">
                  <p>भिडियो</p>
                </a>
              </li>
              <li className="">
                <a href="https://loksewabazaar.com/gorkha-patra">
                  <p>गोरखापत्र</p>
                </a>
              </li>
              <li className="">
                <a href="https://loksewabazaar.com/iq">
                  <p>बौद्धिक स्तर (IQ)</p>
                </a>
              </li>
              <li className="">
                <a href="https://loksewabazaar.com/syllabus">
                  <p>पाठ्यक्रम</p>
                </a>
              </li>
              <li className="">
                <a href="https://loksewabazaar.com/pdf-note">
                  <p>PDF नोटहरू</p>
                </a>
              </li>

              <li className="">
                <NavLink to="/">
                  <p>कोर्सहरु</p>
                </NavLink>
              </li>
              <li className="">
                <NavLink to="/mcqpackages">
                  <p>MCQ Packages</p>
                </NavLink>
              </li>
              <li className="">
                <a href="https://loksewabazaar.com/exam-centre">
                  <p>
                    परीक्षा केन्द्र हेर्नुहोस् 
                    <span>
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </p>
                </a>
              </li>
              {/* <li className="">
            <a href="">
            <p> MCQ Package 
             
              </p>
            </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
