const initialState = {
  data: null,
  loading: false,
  singleData: null,
};

const CourseVideoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_COURSE_VIDEO":
      return {
        data: null,
        loading: true,
      };

    case "COURSE_VIDEO_FETCH_SUCCESS":
      return {
        loading: false,
        data: payload,
      };

    case "COURSE_VIDEO_FETCH_SUCCESS_SINGLE":
      return {
        ...state,
        loading: false,
        singleData: payload,
      };

    case "COURSE_VIDEO_FETCH_FAILURE":
      return {
        data: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default CourseVideoReducer;
