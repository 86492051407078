import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Marksheet from "../Pages/Marksheet";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Terms from "../Pages/Terms";
import PrivateRouting from "./ProfileRouting";
// import CourseDetail from "../Pages/CourseDetail/CourseDetail";
// import Home from "../Components/Home/Home";
// import BuyCourse from "../Pages/BuyCourse/BuyCourse";
// import WatchVideo from "../Pages/WatchVideo/WatchVideo";
// import PdfNote from "../Pages/PdfNote/PdfNote";
// import Mcq from "../Pages/Mcq/Mcq";
// import BankingDetail from "../Pages/BankingDetail/BankingDetail";
// import McqPackageSets from "../Pages/McqPackageSets/McqPackageSets";
// import Profile from "../Pages/Profile/Profile";
// import GiveQuestion from "../Pages/McqPackageSets/GiveQuestion";
// import McqPackage from "../Pages/McqPackages/McqPackage";
// import VerifyRegisterCode from "../Pages/VerifyRegisterCode/VerifyRegisterCode";
// import SendVerificationCode from "../Pages/ForgotPassword/SendVerificationCode";
// import CheckVerificationCode from "../Pages/ForgotPassword/CheckVerificationCode";
// import ChangeForgotPassword from "../Pages/ForgotPassword/ChangeForgotPassword";
// import PackageDetail from "../Pages/McqPackages/PackageDetail";
// import BankingDetailPackage from "../Pages/BankingDetail/BankingDetailPackage";
// import CheckSolutions from "../Pages/McqPackageSets/CheckSolutions";
// import ChangePassword from "../Pages/ChangePassword/ChangePassword";

const Home = lazy(() => import("../Components/Home/Home"));
const BuyCourse = lazy(() => import("../Pages/BuyCourse/BuyCourse"));
const CourseDetail = lazy(() => import("../Pages/CourseDetail/CourseDetail"));
const PackageDetail = lazy(() => import("../Pages/McqPackages/PackageDetail"));
const WatchVideo = lazy(() => import("../Pages/WatchVideo/WatchVideo"));
const PdfNote = lazy(() => import("../Pages/PdfNote/PdfNote"));
const Mcq = lazy(() => import("../Pages/Mcq/Mcq"));
const BankingDetail = lazy(() =>
  import("../Pages/BankingDetail/BankingDetail")
);
const BankingDetailPackage = lazy(() =>
  import("../Pages/BankingDetail/BankingDetailPackage")
);
const VerifyRegisterCode = lazy(() =>
  import("../Pages/VerifyRegisterCode/VerifyRegisterCode")
);
const SendVerificationCode = lazy(() =>
  import("../Pages/ForgotPassword/SendVerificationCode")
);
const CheckVerificationCode = lazy(() =>
  import("../Pages/ForgotPassword/CheckVerificationCode")
);
const ChangeForgotPassword = lazy(() =>
  import("../Pages/ForgotPassword/ChangeForgotPassword")
);
const McqPackage = lazy(() => import("../Pages/McqPackages/McqPackage"));
const McqPackageSets = lazy(() =>
  import("../Pages/McqPackageSets/McqPackageSets")
);
const GiveQuestion = lazy(() => import("../Pages/McqPackageSets/GiveQuestion"));
const CheckSolutions = lazy(() =>
  import("../Pages/McqPackageSets/CheckSolutions")
);
const Profile = lazy(() => import("../Pages/Profile/Profile"));
const ChangePassword = lazy(() =>
  import("../Pages/ChangePassword/ChangePassword")
);
const Homerouting = () => {
  return (
    <>
      <Suspense fallback={<p>loading</p>}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/coursedetail/buycourse/:id"
            element={<BuyCourse />}
          />
          <Route exact path="/coursedetail/:id" element={<CourseDetail />} />

          <Route exact path="/packagedetail/:id" element={<PackageDetail />} />
          <Route
            exact
            path="/coursedetail/buycourse/watchvideo"
            element={<WatchVideo />}
          />
          <Route
            exact
            path="/coursedetail/buycourse/notes/:id"
            element={<PdfNote />}
          />
          <Route
            exact
            path="/coursedetail/buycourse/mcqquestion"
            element={<Mcq />}
          />
          <Route exact path="/bankingdetail/:id" element={<BankingDetail />} />
          <Route
            exact
            path="/bankingdetailPackage/:id"
            element={<BankingDetailPackage />}
          />
          <Route exact path="/verify-code" element={<VerifyRegisterCode />} />
          <Route
            exact
            path="/get-verification-code"
            element={<SendVerificationCode />}
          />
          <Route
            exact
            path="/check-verification-code"
            element={<CheckVerificationCode />}
          />
          <Route
            exact
            path="/change-forgot-password"
            element={<ChangeForgotPassword />}
          />
          <Route exact path="/mcqpackages" element={<McqPackage />} />
          <Route
            exact
            path="/mcqpackagesets/:id"
            element={<McqPackageSets />}
          />
          <Route
            exact
            path="/mcqpackagesets/question-set/:id"
            element={<GiveQuestion />}
          />
          <Route
            exact
            path="/mcqpackagesets/question-set/check-solutions/:id"
            element={<CheckSolutions />}
          />
          {/* Private Routing */}
          <Route path="/profile/*" element={<PrivateRouting />}>
            <Route path="" element={<Profile />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>

          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />

          <Route path="/terms&conditions" exact element={<Terms />} />

          <Route path="/marksheet" exact element={<Marksheet />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Homerouting;
