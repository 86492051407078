import axios from "axios";

export const fetchInformationRequest = () => {
  return {
    type: "FETCHING_INFORMATION",
  };
};

export const fetchSuccess = (payload) => {
  return {
    type: "INFORMATION_FETCH_SUCCESS",
    payload: payload,
  };
};

export const fetchFailure = (message) => {
  return {
    type: "INFORMATION_FETCH_FAILURE",
    payload: message,
  };
};

export const fetchInformation = () => {
  return async (dispatch) => {
    dispatch(fetchInformationRequest());
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_URL}/get-setting`,
    })
      .then((res) => dispatch(fetchSuccess(res.data.data.setting)))
      .catch((e) => dispatch(fetchFailure(e.message)));
  };
};
