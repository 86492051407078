const initialState = {
  data: null,
  loading: false,
  singleData: null,
  questions:null,
  boughtPackages: [],
};

const MCQSetReducer = (state = initialState, { type, payload }) => { 
  switch (type) {
    case "FETCHING_MCQ_SET":
      return {
        ...state,
        data: null,
        loading: true,
      };

    case "MCQ_SET_FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        data: payload,
      };

    case "BOUGHT_MCQ_SUCCESS":
      return {
        ...state,
        loading:false,
        boughtPackages:payload
      }

    case "MCQ_SET_QUESTIONS_SUCCESS":
      return{
        ...state,
        loading:false,
        questions:payload
      };

    case "MCQ_SET_FETCH_SUCCESS_SINGLE":
      return {
        ...state,
        loading: false,
        singleData: payload,
      };

    case "MCQ_SET_FETCH_FAILURE":
      return {
        ...state,
        data: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default MCQSetReducer;
