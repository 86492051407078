import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer/LoginReducer";
import CourseReducer from "./CourseReducer";
import { CheckVerificationCodeReducer } from "./ForgotPasswordReducer/CheckVerificationCodeReducer";
import PackageReducer from "./MCQPackageReducer";
import RegisterCodeReducer from "./RegisterCodeReducer/RegisterCodeReducer";
import RegisterReducer from "./RegisterReducer/RegisterReducer";
import { ChangeForgotPasswordReducer } from "./ForgotPasswordReducer/ChangeForgotPasswordReducer";
import PaymentInfoReducer from "./PaymentInfoReducer";
import { SendVerificationCodeReducer } from "./ForgotPasswordReducer/SendVerificationCodeReducer";
import InformationReducer from "./InformationReducer";
import MCQSetReducer from "./MCQSets";
import CourseVideoReducer from "./CourseVideoReducer";
import CourseNoteReducer from "./CourseNoteReducer";
import CourseSetReducer from "./CourseSetReducer";
import BankingDetailReducer from "./BankingDetail/BankingDetailReducer";
import { changePasswordReducer } from "./ChangePasswordReducer/ChangePasswordReducer";

const rootReducer = combineReducers({
  LoginReducer,
  CourseReducer,
  PackageReducer,
  RegisterReducer,
  RegisterCodeReducer,
  ChangeForgotPasswordReducer,
  SendVerificationCodeReducer,
  CheckVerificationCodeReducer,
  PaymentInfoReducer,
  InformationReducer,
  MCQSetReducer,
  CourseVideoReducer,
  CourseNoteReducer,
  CourseSetReducer,
  BankingDetailReducer,
  changePasswordReducer,
});

export default rootReducer;
