const initialState = {
  data: null,
  loading: false,
  message: {
    success: null,
    error: null,
  },
  credentials:null
};

export const SendVerificationCodeReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "SEND_VERIFICATION_CODE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SEND_VERIFICATION_CODE_SUCCESS":
      return {
        loading: false,
        data: payload.data,
        message: {
          success: payload.message,
          error: null,
        },
        credentials:payload.credentials
      };
    case "SEND_VERIFICATION_CODE_ERROR":
      return {
        data: null,
        loading: false,
        message: {
          error: payload,
          success: null,
        },
      };
    case "CLEAR_MESSAGE":
      return {
        ...state,
        loading: false,
        message: {
          error: null,
          success: null,
        },
      };
    default:
      return state;
  }
};
