import React from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
const Footer = () => {
  const information = useSelector((state) => state.InformationReducer);
  return (
    <>
      <div className="main-footer">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-4">
              <div className="footer-content">
                <h3>Location</h3>

               <div className="footer-map">
               {parse(information?.data?.google_location || "")}
               </div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14132.058636846925!2d85.3450849!3d27.6859418!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6064967133397f28!2sCreatu%20Developers!5e0!3m2!1sen!2snp!4v1649829842315!5m2!1sen!2snp"
                  className="maping"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="footer-content">
                <h3>links</h3>
                <ul>
                  <li className="footer-li">
                    <a href="https://www.rbb.com.np/" target={"_blank"}>
                      राष्ट्रिय बाणिज्य बैंक लिमिटेड
                    </a>
                  </li>
                  <li className="footer-li">
                    <a href="https://www.nepalbank.com.np/" target={"_blank"}>
                      नेपाल बैंक लिमिटेड
                    </a>
                  </li>
                  <li className="footer-li">
                    <a href="https://ppsc.p5.gov.np/" target={"_blank"}>
                      प्रदेश नं. ५ लोकसेवा आयोग
                    </a>
                  </li>
                  <li className="footer-li">
                    <a href="https://ppsc.p2.gov.np/" target={"_blank"}>
                      प्रदेश नं. २ लोकसेवा आयोग
                    </a>
                  </li>
                  <li className="footer-li">
                    <a href="http://psc.p1.gov.np/" target={"_blank"}>
                      प्रदेश नं. १ लोकसेवा आयोग
                    </a>
                  </li>
                  <li className="footer-li">
                    <a href="http://spsc.bagamati.gov.np/" target={"_blank"}>
                      बाग्मती प्रदेश लोकसेवा आयोग
                    </a>
                  </li>
                  <li className="footer-li">
                    <a href="https://www.nrb.org.np/" target={"_blank"}>
                      नेपाल राष्ट्र बैंक
                    </a>
                  </li>
                  <li className="footer-li">
                    <a
                      href="https://lawcommission.gov.np/np/"
                      target={"_blank"}
                    >
                      नेपाल कानून आयोग
                    </a>
                  </li>
                  <li className="footer-li">
                    <a href="https://psc.gov.np/" target={"_blank"}>
                      लोकसेवा आयोग
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-content-two">
                <h3>Contact</h3>
                <div className="footer-contact">
                  <p>{information?.data?.address}</p>
                  <p>
                    {" "}
                    <span>
                      <i class="fa-solid fa-phone"></i>
                    </span>{" "}
                    {information?.data?.contact1}
                  </p>

                  <p>
                    {" "}
                    <span>
                      <i class="fa-solid fa-envelope"></i>
                    </span>{" "}
                    {information?.data?.email1}
                  </p>
                  <ul>
                    <li>
                      <p className="footer-icon-one">
                        <a
                          href={information?.data?.facebook_link}
                          target="_blank"
                        >
                          <i class="fa-brands fa-facebook-f"></i>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p className="footer-icon-two">
                        <a
                          href={information?.data?.twitter_link}
                          target="_blank"
                        >
                          <i class="fa-brands fa-twitter"></i>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p className="footer-icon-three">
                        <i class="fa-brands fa-google-plus-g"></i>
                      </p>
                    </li>
                    <li>
                      <p className="footer-icon-four">
                        <a
                          href={information?.data?.linkedin_link}
                          target="_blank"
                        >
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p className="footer-icon-five">
                        <a
                          href={information?.data?.youtube_link}
                          target="_blank"
                        >
                          <i class="fa-brands fa-youtube"></i>
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="footer-content">
                <h3>Policies</h3>
                <ul>
                  <li className="footer-li">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className="footer-li">
                    <Link to="/terms&conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="developed-main clearfix">
        <div className="container">
          <p className="left">
            Copyright © {new Date().getFullYear()} LoksewaBazaar
          </p>
          <p className="right">Developed By Creatu Developers</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
