import React, { useState, useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import CourseDetailLoader from "./CourseDetail/CourseDetailLoader";
import PrivacyLoader from "./PrivacyLoader";
function PrivacyPolicy() {
  const [privacy, setPrivacy] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_URL}/get-privacy`,
    })
      .then((res) => {
        // console.log(res);
        setPrivacy(res.data.data.privacy);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="container mb-5">
        <div className="main-topic mt-5">
          <h3>Privacy Policy</h3>
        </div>{" "}
        {loading ? (
          <PrivacyLoader />
        ) : (
          <p>{parse(privacy?.description || "")}</p>
        )}
      </div>
    </>
  );
}

export default PrivacyPolicy;
