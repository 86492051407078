import React, { useEffect } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
function Marksheet({ correct, incorrect, accuracy, totalQuestions }) {
  return (
    <div class="row d-flex justify-content-center mb-5">
      <div class="col pro-col">
        <CircularProgressbar
          value={(correct / totalQuestions) * 100}
          text={`${correct}`}
          styles={buildStyles({
            pathColor: `rgb(6, 93, 51)`,
          })}
        />
        <div class="pro-text">Correct Answer</div>
      </div>

      <div class="col pro-col">
        <CircularProgressbar
          value={(incorrect / totalQuestions) * 100}
          text={`${incorrect}`}
          styles={buildStyles({
            pathColor: `rgb(238, 37, 1)`,
          })}
        />
        <div class="pro-text">Incorrect Answer</div>
      </div>

      <div class="col pro-col">
        <CircularProgressbar
          value={accuracy}
          text={`${accuracy}`}
          styles={buildStyles({
            pathColor: `rgb(30, 102, 236)`,
          })}
        />
        <div class="pro-text">Accuracy</div>
      </div>
    </div>
  );
}

export default Marksheet;
