import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../Service/Action/LoginAction/LoginAction";
import { RegisterUser } from "../../Service/Action/RegisterAction/RegisterAction";

// Lib imports
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";

export default function Login() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [register, setRegister] = useState({
    name: "",
    email: "",
    phone_no: "",
    password: "",
    confirm_password: "",
  });

  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.LoginReducer);
  const RegisterState = useSelector((state) => state.RegisterReducer);

  const closeModal = () => {
    document.querySelector("#close").click();
  };

  useEffect(() => {
    if (loginState.message.success) {
      enqueueSnackbar(loginState.message.success, { variant: "success" });
      closeModal();
      setLogin({
        email: "",
        password: "",
      });
    }

    if (loginState.message.error) {
      enqueueSnackbar(loginState.message.error, { variant: "error" });
      // closeModal();
    }
  }, [loginState]);

  useEffect(() => {
    if (RegisterState.message.success) {
      enqueueSnackbar(RegisterState.message.success, { variant: "success" });
      closeModal();
      setRegister({
        name: "",
        email: "",
        phone_no: "",
        password: "",
        confirm_password: "",
      });
    }

    if (RegisterState.message.error) {
      enqueueSnackbar(RegisterState.message.error, { variant: "error" });
    }
  }, [RegisterState]);

  const loginHandler = (e) => {
    e.preventDefault();
    dispatch(LoginUser(login, navigate));
  };

  const RegisterHandler = (e) => {
    e.preventDefault();
    if (register.confirm_password !== register.password) {
      enqueueSnackbar("Your password doesnot match", { variant: "error" });
      return;
    }
    if (
      register.name === "" &&
      register.phone_no === "" &&
      register.email === "" &&
      register.password === "" &&
      register.confirm_password === ""
    ) {
      enqueueSnackbar("Empty Field Detected", { variant: "error" });
    } else {
      dispatch(RegisterUser({ ...register }, navigate));
    }

    // navigate("/verify-code", { state: { registerData: register } });
  };

  return (
    <>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <div className="modal-image modal-title" id="staticBackdropLabel">
                <img src={logo} alt="" />
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close"
              ></button>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Login
                  </button>
                </li>
                <li class="nav-item nav-item-one" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Register
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <form className="sign-in-up">
                    {/* {loginState.message.success && (
                      <Alert severity="success">
                        {loginState.message.success}
                      </Alert>
                    )} */}
                    {/* {RegisterState.message.success && (
                      <Alert severity="success">
                        {RegisterState.message.success} 
                      </Alert>
                    )} */}

                    {/* {loginState.message.error && (
                      <Alert severity="error">{loginState.message.error}</Alert>
                    )}
                    {RegisterState.message.error && (
                      <Alert severity="error">
                        {RegisterState.message.error}
                      </Alert>
                    )} */}

                    <div className="form-input-sign form-group">
                      <input
                        type="email"
                        placeholder="Email"
                        value={login.email}
                        onChange={(e) =>
                          setLogin({ ...login, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-input-sign">
                      <input
                        type="password"
                        placeholder="Password"
                        value={login.password}
                        onChange={(e) =>
                          setLogin({ ...login, password: e.target.value })
                        }
                      />
                    </div>
                    <div className="modal-btn">
                      {loginState.loading ? (
                        <CircularProgress />
                      ) : (
                        <button onClick={loginHandler}>Log In</button>
                      )}
                    </div>
                    <div className="forget-password">
                      <NavLink to="/get-verification-code">
                        <p
                          aria-label="Close"
                          data-bs-dismiss="modal"
                          id="close"
                        >
                          Forgot Password ?
                        </p>
                      </NavLink>
                    </div>
                    <div className="continue-with">
                      <p>Continue with</p>
                      <ul>
                        <li>
                          <p>
                            <i class="fa-brands fa-facebook-f"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa-brands fa-twitter"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa-brands fa-google"></i>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form className="sign-in-up">
                    <div className="form-input-sign">
                      <input
                        type="text"
                        placeholder="Full Name"
                        value={register.name}
                        onChange={(e) =>
                          setRegister({ ...register, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-input-sign">
                      <input
                        type="email"
                        placeholder="Email"
                        value={register.email}
                        onChange={(e) =>
                          setRegister({
                            ...register,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-input-sign">
                      <input
                        type="text"
                        placeholder="Number"
                        value={register.phone_no}
                        onChange={(e) =>
                          setRegister({ ...register, phone_no: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-input-sign">
                      <input
                        type="password"
                        placeholder="Password"
                        value={register.password}
                        onChange={(e) =>
                          setRegister({ ...register, password: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-input-sign">
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        value={register.confirm_password}
                        onChange={(e) =>
                          setRegister({
                            ...register,
                            confirm_password: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="modal-btn">
                      {RegisterState.loading ? (
                        <CircularProgress />
                      ) : (
                        <button onClick={RegisterHandler}>Register Now</button>
                      )}
                    </div>
                    <div className="continue-with">
                      <p>Continue with</p>
                      <ul>
                        <li>
                          <p>
                            <i class="fa-brands fa-facebook-f"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa-brands fa-twitter"></i>
                          </p>
                        </li>
                        <li>
                          <p>
                            <i class="fa-brands fa-google"></i>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
}
