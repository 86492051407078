import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./Service/Reducer";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { composeWithDevTools } from "redux-devtools-extension";
import { save, load } from "redux-localstorage-simple";
import ThunkMiddleware from "redux-thunk";

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(
    ThunkMiddleware
    // save({ states: ["LoginReducer"] }) // Saving done here
  )
)(createStore);

const store = createStoreWithMiddleware(
  rootReducer
  // load({ states: ["LoginReducer"] }) // Loading done here
);
// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(ThunkMiddleware, save(), load()))
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
