import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import parse from "html-react-parser";
import PrivacyLoader from "./PrivacyLoader";

function Terms() {
  const [term, setTerm] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_URL}/get-term-condition`,
    })
      .then((res) => {
        // console.log(res);
        setTerm(res.data.data.termsAndCondition);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="container mb-5">
        <div className="main-topic mt-5">
          <h3>Terms & Conditions</h3>
        </div>{" "}
        {loading ? <PrivacyLoader /> : <p>{parse(term?.description || "")}</p>}
      </div>
    </>
  );
}

export default Terms;
