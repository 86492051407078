import { AuthAPI } from "../../../Components/Auth/AuthAPI";
import { CONSTANTS } from "../../../constants";

export const LoginUserRequest = () => {
  return {
    type: "LOGIN_USER_REQUEST",
  };
};

export const LogoutRequest = () => {
  return {
    type: "LOGOUT_REQUEST",
  };
};

export const LoginUserSuccess = (payload) => {
  return {
    type: "LOGIN_USER_SUCCESS",
    payload: payload,
  };
};

export const LogoutSuccess = () => {
  return {
    type: "LOGOUT_SUCCESS",
  };
};

export const LogoutFailure = () => {
  return {
    type: "LOGOUT_FAILURE",
  };
};

export const LoginUserError = (payload) => {
  return {
    type: "LOGIN_USER_ERROR",
    payload: payload,
  };
};

export const ClearUser = () => {
  return {
    type: "CLEAR_USER",
  };
};

export const ClearMessage = () => {
  return {
    type: "CLEAR_MESSAGE",
  };
};

export const LoginUser = (credentials, navigate) => {
  return async (dispatch) => {
    dispatch(LoginUserRequest());
    await AuthAPI.login(credentials)
      .then((res) => {
        if (res.data.status) {
          dispatch(
            LoginUserSuccess({
              message: "Login Successful",
              data: res.data.data,
            })
          );
          // navigate('/')
        } else {
          dispatch(LoginUserError("Failed to login"));
        }
      })
      .catch((e) => {
        dispatch(
          LoginUserError(e.response?.data ? e.response.data.message : e.message)
        );
      });

    setTimeout(() => {
      dispatch(ClearMessage());
    }, 100);
  };
};

export const LogoutUser = (token, navigate) => {
  return async (dispatch) => {
    await dispatch(ClearUser());

    await dispatch(LogoutRequest());

    window.location.href = "https://loksewabazaar.com/profile/logout";
    // await AuthAPI.logout(token, navigate)
    //   .then((res) => {
    //     if (res.data.status) {
    //       dispatch(LogoutSuccess("Logout Success"));
    //       navigate("/");
    //     } else {
    //       dispatch(LogoutFailure());
    //     }
    //   })
    //   .catch((e) => dispatch(LogoutFailure()));
    // navigate("/");
    dispatch(LogoutSuccess("Logout Success"));

    setTimeout(() => {
      dispatch(ClearMessage());
    }, CONSTANTS.ALERT_TIME_OUT);
  };
};
