import React from "react";
import logo from "../../Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import Login from "../Auth/Login";
import { LogoutUser } from "../../Service/Action/LoginAction/LoginAction";
export default function TopHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.LoginReducer);

  const token = user?.data?.access_token;

  const logOutHandler = () => {
    dispatch(LogoutUser(token, navigate));
  };
  return (
    <div className="top-header container-fluid">
      <div className="top-flexbox">
        <div className="top-flex-item left">
          <NavLink to="/" className="link">
            <img src={logo} alt="Logo" />
          </NavLink>
        </div>
        <div className="top-flex-item clock">
          <iframe
            src="https://calendar-nepali.com/clockwidget/nepali-time-and-date-text-mini.php"
            frameborder="0"
            scrolling="no"
            marginwidth="0"
            marginheight="0"
            style={{
              border: "none",
              overflow: "hidden",
              width: "100%",
              height: "50px",
            }}
            allowtransparency="true"
          ></iframe>
        </div>
        <div className="top-flex-item right">
          <div className="user-main">
            {user.data === null ? (
              <ul>
                <span></span>
                <li className="login-link login log-show ">
                  <a href="http://loksewabazaar.com/user-login">
                    <i class="fa-solid fa-user "> </i>
                  </a>
                </li>
                {/* <li className="login-link login log-hidden">
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    className="log-hidden"
                  >
                    Login/SignUp
                  </span>
                </li> */}
              </ul>
            ) : (
              <ul>
                <li className="login-text">
                  <NavLink to="/profile">
                    {user?.data?.user?.avatar ? (
                      <img src={user?.data?.user?.avatar} width="50px" />
                    ) : (
                      <i class="fa-solid fa-circle-user"></i>
                    )}{" "}
                    View Profile
                  </NavLink>
                </li>
                {/* <li className="login-link logout" onClick={logOutHandler}>
                  <i class="fa-solid fa-right-from-bracket"></i> Logout
                </li> */}
              </ul>
            )}

            <Login />
          </div>
        </div>
      </div>
      <div className="top-flex-item hidden-clock">
        <iframe
          src="https://calendar-nepali.com/clockwidget/nepali-time-and-date-text-mini.php"
          frameborder="0"
          scrolling="no"
          marginwidth="0"
          marginheight="0"
          style={{
            border: "none",
            overflow: "hidden",
            width: "100%",
            height: "50px",
          }}
          allowtransparency="true"
        ></iframe>
      </div>
    </div>
  );
}
