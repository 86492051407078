const initialState = {
  data: null,
  message: {
    error: null,
    success: null,
  },
  loading: false,
};

export const changePasswordReducer = (
  state = initialState,
  { payload, type }
) => {
  switch (type) {
    case "CHANGE_PASSWORD_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "CHANGE_PASSWORD_SUCCESS":
      return {
        loading: false,
        data: payload,
        message: {
          success: payload.message,
          error: null,
        },
      };
    case "CHANGE_PASSWORD_FAILURE":
      return {
        loading: false,
        data: null,
        message: {
          success: null,
          error: payload,
        },
      };

    case "CLEAR_MESSAGE":
      return {
        ...state,
        message: {
          success: null,
          error: null,
        },
      };
    default:
      return state;
  }
};
