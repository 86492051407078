import "./App.css";
import React, { useState, useEffect } from "react";
import Header from "./Components/Header/Header";
import Homerouting from "./HomeRouting/Homerouting";
import Footer from "./Components/Footer/Footer";
import { useDispatch } from "react-redux";
import { fetchPayment } from "./Service/Action/PaymentInfoAction";
import { fetchInformation } from "./Service/Action/InformationAction";
import { LoginUserSuccess } from "./Service/Action/LoginAction/LoginAction";

function App() {
  // const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  // function setCookie() {
  //   document.cookie = "Website" + "=" + "Loksewa Bazaar";
  // }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  useEffect(() => {
    console.log("Bearer token is", getCookie("bearer_token"));

    dispatch(fetchPayment());
    dispatch(fetchInformation());

    // document.cookie = "bearer_token" + "=" + "hello";
    if (getCookie("bearer_token")) {
      dispatch(
        LoginUserSuccess({
          message: null,
          data: {
            user: getCookie("bearer_token"),
            access_token: getCookie("bearer_token"),
          },
        })
      );
    }

    // console.log("Cookies are", getCookie("bearer_token"));
  }, []);

  // useEffect(() => {
  //   localStorage.setItem("user", JSON.stringify(user));
  // }, [user]);
  return (
    <>
      <Header />
      <Homerouting />
      <Footer />
    </>
  );
}

export default App;
