import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

function PrivateRouting() {
  const [cookie] = useCookies();
  if (!cookie.bearer_token) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
}

export default PrivateRouting;
