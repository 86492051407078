const initialState = {
  data: null,
  message: {
    error: null,
    success: null,
  },
  loading: false,
};

const LoginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOGIN_USER_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "LOGIN_USER_SUCCESS":
      return {
        loading: false,
        data: payload.data,
        message: {
          success: payload.message,
          error: null,
        },
      };

    case "LOGIN_USER_ERROR":
      return {
        data: null,
        loading: false,
        message: {
          error: payload,
          success: null,
        },
      };

    case "CLEAR_MESSAGE":
      return {
        ...state,
        loading: false,
        message: {
          error: null,
          success: null,
        },
      };

    case "LOGOUT":
      return {
        ...state,
        loading: true,
      };

    case "LOGOUT_SUCCESS":
      return {
        ...state,
        loading: false,
        data: null,
        message: {
          success: null,
          error: payload,
        },
      };

    case "LOGOUT_FAILURE":
      return {
        ...state,
        loading: false,
      };

    case "CLEAR_USER":
      return {
        data: null,
        message: {
          error: null,
          success: null,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default LoginReducer;
