import axios from "axios";
export const AuthAPI = {
  login: (data) => { 
    return axios({
      method: "POST",
      data: data,
      url: `${process.env.REACT_APP_URL}/user/login`,
    });
  },

  getRegsitrationCode: (data) => {
    return axios({
      method: "POST",
      data: data,
      url: `${process.env.REACT_APP_URL}/user/register/send-verifycode`,
    });
  },

  checkRegsitrationCode: (data) => {
    return axios({
      method: "POST",
      data: data,
      url: `${process.env.REACT_APP_URL}/user/checkVerificationCode`,
    });
  },

  logout: (token) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_URL}/user/logout`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
