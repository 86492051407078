const initialState = {
  data: null,
  loading: false,
  singleData: null,
  boughtPackages: [],
};

const PackageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_PACKAGE":
      return {
        data: null,
        loading: true,
      };

    case "PACKAGE_FETCH_SUCCESS":
      return {
        loading: false,
        data: payload,
      };

    case "PACKAGE_FETCH_SUCCESS_SINGLE":
      return {
        ...state,
        loading: false,
        singleData: payload,
      };

    case "BOUGHT_PACKAGE_FETCH_SUCCESS": {
      return {
        ...state,
        loading: false,
        boughtPackages: payload,
      };
    }

    case "BOUGHT_PACKAGE_FETCH_FAILURE": {
      return {
        ...state,
        loading: false,
        boughtPackages: payload,
        boughtPackages: [],
      };
    }

    case "PACKAGE_FETCH_FAILURE":
      return {
        data: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default PackageReducer;
