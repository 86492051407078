import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function CourseDetailLoader() {
  return (
    <Stack spacing={3}>
      <div className="row course-loader">
        <div className="col-md-9">
          <Skeleton variant="rectangular" height={"25rem"} />
          <Skeleton variant="text" width={"40%"} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>

        <div className="col-md-3">
          <Skeleton variant="text" width={"40%"} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>
      </div>
    </Stack>
  );
}
