const initialState = {
  data: null,
  loading: false,
  message: {
    success: null,
    error: null,
  },
};

const RegisterCodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "VERIFY_CODE_REGISTER":
      return {
        ...state,
        loading: true,
      };

    case "CODE_REGISTER_SUCCESS":
      return {
        loading: false,
        message: {
          success: payload.message,
        },
        data: payload.data,
      };

    case "CODE_REGISTER_ERROR":
      return {
        loading: false,
        message: {
          error: payload,
          success: null,
        },
        data: null,
      };

    case "CLEAR_MESSAGE":
      return {
        ...state,
        loading: false,
        message: {
          error: null,
          success: null,
        },
      };
    default:
      return state;
  }
};
export default RegisterCodeReducer;
